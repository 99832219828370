<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header>
        <b-button-group>
          <b-button
            v-for="item in hiLevelDirectories"
            :key="item.key"
            :variant="item.key === categorySelected ? 'primary' : 'outline-primary'"
            @click="onCategoryButtonClickHandler(item.key)"
          >
            {{ item.label }}
          </b-button>
        </b-button-group>
      </b-card-header>
      <v-table
        v-if="categorySelected"
        ref="table"
        :path="apiPath"
        :fields="tableColumns"
        @create="onCreateHandler"
      >
        <template #cell(serial)="data">
          <v-table-serial-column :data="data"/>
        </template>
        <template #cell(actions)="{ item }">
          <v-table-actions-column
            :view="false"
            @edit="onEditHandler(item)"
            @delete="onDeleteHandler(item)"
          />
        </template>
      </v-table>
      <b-card-body v-else>
        <h1>Выберите категорию</h1>
      </b-card-body>
    </b-card>
    <b-modal
      ref="form-modal"
      :title="formDataToCreate ? 'Добавить' : 'Редактировать'"
      :hide-footer="true"
    >
      <b-overlay
        :show="overloadShow"
      >
        <directories-form
          :value="formData"
          @cancel="$refs['form-modal'].hide()"
          @save="onFormSaveHandler"
        />
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BButtonGroup, BCard, BCardBody, BCardHeader, BModal, BOverlay,
} from 'bootstrap-vue'
import VTable, { VTableSerialColumn, VTableActionsColumn } from '@/views/components/vtable'
import { hiLevelDirectories, tableColumns } from './directoriesData'
import DirectoriesForm from './DirectoriesForm.vue'

const emptyFormData = {
  code: null,
  title: null,
  description: null,
  params: {},
}

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BButtonGroup,
    BModal,
    BOverlay,
    VTable,
    VTableSerialColumn,
    VTableActionsColumn,
    DirectoriesForm,
  },
  data() {
    return {
      hiLevelDirectories,
      categorySelected: null,
      tableColumns,
      formData: null,
      formDataToCreate: true,
      overloadShow: false,
    }
  },
  computed: {
    apiPath() {
      return `/directories/${this.categorySelected}`
    },
  },
  methods: {
    resetFormData() {
      this.formData = { ...emptyFormData }
    },
    onCategoryButtonClickHandler(newCategory) {
      if (this.categorySelected !== newCategory) {
        this.categorySelected = newCategory
      }
    },
    onCreateHandler() {
      this.resetFormData()
      this.formDataToCreate = true
      this.$refs['form-modal'].show()
    },
    onFormSaveHandler(data) {
      let url = `directories/${this.categorySelected}`
      if (!this.formDataToCreate) {
        url += `/${data.code}`
      }
      this.overloadShow = true
      this.$axios[this.formDataToCreate ? 'post' : 'put'](url, data)
        .then(() => {
          this.$refs.table.refresh()
          this.$refs['form-modal'].hide()
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.$bvToast.toast(response.data.message, {
              variant: 'danger',
              title: 'Ошибка',
            })
          } else {
            this.$bvToast.toast('Не известная ошибка!', {
              variant: 'danger',
              title: 'Ошибка',
            })
          }
        })
        .finally(() => {
          this.overloadShow = false
        })
    },
    onEditHandler(item) {
      this.formDataToCreate = false
      this.formData = { ...item }
      this.$refs['form-modal'].show()
    },
  },
}
</script>
