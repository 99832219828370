<template>
  <div>
    <b-form-group
      label="Название"
    >
      <b-form-input
        v-model="formData.title"
      />
    </b-form-group>
    <b-form-group
      label="Код"
    >
      <b-form-input
        v-model="formData.code"
      />
      <small>Уникальный для категории</small>
    </b-form-group>
    <b-form-group
      label="Описание"
    >
      <b-form-textarea
        v-model="formData.description"
      />
    </b-form-group>
    <div class="d-flex justify-content-end">
      <b-button
        variant="light"
        @click="$emit('cancel')"
      >Отмена</b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="$emit('save', formData)"
      >Сохранить</b-button>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BFormTextarea, BButton } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
    }
  },
  mounted() {
    this.formDataSetter()
  },
  methods: {
    formDataSetter() {
      this.formData = { ...this.value }
    },
  },
}
</script>

<style scoped>

</style>