export const hiLevelDirectories = [
  {
    key: 'out_key',
    label: 'Источники'
  },
  {
    key: 'operation',
    label: 'Операции',
  },
  {
    key: 'car_complect',
    label: 'Комплектация авто',
  },
  {
    key: 'tags',
    label: 'Тэги',
  },
  {
    key: 'other',
    label: 'Разное',
  },
]

export const tableColumns = [
  {
    key: 'serial',
    label: '#',
  },
  {
    key: 'code',
    label: 'Код',
  },
  {
    key: 'title',
    label: 'Название',
  },
  {
    key: 'description',
    label: 'Описание',
  },
  {
    key: 'actions',
    label: '',
  },
]
